// import React from 'react';
// import {
//     Card,
//     CardContent,
//     CardMedia,
//     Typography,
//     Button,
//     Box,
//     IconButton,
//     CircularProgress,
// } from '@mui/material';
// import Calendar from '../../../assets/images/workshop/calendar_final.svg';
// import Redlive from "../../redlive/redlive";
// import Green from "../../green/Green";
// import ButtonBase from '@mui/material/ButtonBase';

// const WorkshopCard = ({ workshop, loading }) => {
//     return (
//         <ButtonBase 
//             disableRipple
//             component="a"
//             href={workshop.btnLink}
//             sx={{
//                 width: '100%',
//                 display: 'block',
//                 textAlign: 'initial',
//                 '&:hover': {
//                     textDecoration: 'none',
//                 },
//             }}
//         >
//             <Card
//                 sx={{
//                     width: {xs:290,sm:290,md:290},
//                     height: 335,
//                     borderRadius: 6,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'space-between',
//                     border: '1px solid #e0e0e0',
//                     position: 'relative',
//                 }}
//             >
//                 {loading ? (
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             height: '100%',
//                         }}
//                     >
//                         <CircularProgress />
//                     </Box>
//                 ) : (
//                     <>
//                         <CardContent sx={{ padding: 1.5, position: 'relative' }}>
//                             {/* Image Section */}
//                             <CardMedia
//                                 component="img"
//                                 image={workshop.bannerImg}
//                                 alt={workshop.workshop}
//                                 sx={{
//                                     width: '100%',
//                                     height: 140,
//                                     marginBottom: 1.5,
//                                     objectFit: 'cover',
//                                     borderRadius: 4,
//                                 }}
//                             />

//                             {/* Header (Recorded session) positioned over the image */}
//                             <Box
//                                 sx={{
//                                     position: 'absolute',
//                                     top: 21,
//                                     right: 21,
//                                     backgroundColor: 'rgba(255, 255, 255)',
//                                     borderRadius: 2,
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     padding: '4px 6px',
//                                 }}
//                             >
//                                 <IconButton sx={{ width: 8, height: 8, padding: 0, ml: 0.6, mr: 0.6 }}>
//                                     {workshop.gif === 'red' ? <Redlive /> : <Green />}
//                                 </IconButton>

//                                 <Typography
//                                     sx={{
//                                         color: 'black',
//                                         fontFamily: 'Poppins',
//                                         fontSize: 11,
//                                         fontWeight: 500,
//                                         ml: 0.8,
//                                         mt: 0.2,
//                                     }}
//                                 >
//                                     {workshop.status}
//                                 </Typography>
//                             </Box>

//                             {/* Title and Mentor */}
//                             <Typography
//                                 sx={{
//                                     color: '#100031',
//                                     fontFamily: 'Outfit',
//                                     fontSize: 16,
//                                     fontWeight: 600,
//                                     mb: 0.5,
//                                     ml: 0.6,
//                                     textAlign: 'left',
//                                 }}
//                             >
//                                 {workshop.workshop}
//                             </Typography>
//                             <Typography
//                                 sx={{
//                                     color: '#4b4b4b',
//                                     fontFamily: 'Poppins',
//                                     fontSize: 11,
//                                     fontWeight: 300,
//                                     mb: 0.5,
//                                     ml: 0.6,
//                                     textAlign: 'left',
//                                 }}
//                             >
//                                 {workshop.mentor ? `By ${workshop.mentor}` : <br />}
//                             </Typography>

//                             {/* Date Section */}
//                             <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, ml: 0.6 }}>
//                                 <img src={Calendar} alt="Calendar Icon" style={{ width: 14, height: 14, color: '#777777', marginBottom: 0 }} />
//                                 <Typography
//                                     sx={{
//                                         color: 'var(--Filled, #000)',
//                                         fontFamily: 'Poppins',
//                                         fontSize: 12,
//                                         fontWeight: 400,
//                                         ml: 1,
//                                     }}
//                                 >
//                                     {workshop.date}
//                                 </Typography>
//                             </Box>
//                         </CardContent>

//                         {/* Action Button */}
//                         <Button
//                             variant="contained"
//                             sx={{
//                                 width: 264,
//                                 padding: '10px 50px',
//                                 backgroundColor: '#7b3fe4',
//                                 color: '#fff',
//                                 borderRadius: 2,
//                                 textTransform: 'none',
//                                 position: 'absolute',
//                                 bottom: 15,
//                                 left: '50%',
//                                 transform: 'translateX(-50%)',
//                                 boxShadow: 'none', // Removed box shadow
//                                 '&:hover': {
//                                     backgroundColor: '#7b3fe4', // Same color as the default
//                                     opacity: 1, // Ensure opacity remains the same
//                                     boxShadow:"none"
//                                 },
//                             }}
//                             href={workshop.btnLink}
//                         >
//                             {workshop.button || 'Know more'}
//                         </Button>
//                     </>
//                 )}
//             </Card>
//         </ButtonBase>
//     );
// };

// export default WorkshopCard;


// import React from 'react';
// import {
//     Card,
//     CardContent,
//     CardMedia,
//     Typography,
//     Button,
//     Box,
//     IconButton,
//     CircularProgress,
// } from '@mui/material';
// import Calendar from '../../../assets/images/workshop/calendar_final.svg';
// import Redlive from "../../redlive/redlive";
// import Green from "../../green/Green";
// import ButtonBase from '@mui/material/ButtonBase';

// const WorkshopCard = ({ workshop, loading }) => {
//     return (
//         <ButtonBase 
//             disableRipple
//             component="a"
//             href={workshop.btnLink}
//             sx={{
//                 width: '100%',
//                 display: 'block',
//                 textAlign: 'initial',
//                 '&:hover': {
//                     textDecoration: 'none',
//                 },
//             }}
//         >
//             <Card
//                 sx={{
//                     width: '290px',          // Fixed width for the card
//                     height: 335,
//                     borderRadius: 6,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'space-between',
//                     border: '1px solid #e0e0e0',
//                     position: 'relative',
//                     mx: 'auto',              // Center the card horizontally
//                     '@media (max-width: 480px)': {
//                         width: '90%',        // On smaller screens, make the card take 90% of the screen width
//                     },
//                 }}
//             >
//                 {loading ? (
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             height: '100%',
//                         }}
//                     >
//                         <CircularProgress />
//                     </Box>
//                 ) : (
//                     <>
//                         <CardContent sx={{ padding: 1.5, position: 'relative' }}>
//                             {/* Image Section */}
//                             <CardMedia
//                                 component="img"
//                                 image={workshop.bannerImg}
//                                 alt={workshop.workshop}
//                                 sx={{
//                                     width: '100%',
//                                     height: 140,
//                                     marginBottom: 1.5,
//                                     objectFit: 'cover',
//                                     borderRadius: 4,
//                                 }}
//                             />

//                             {/* Header (Recorded session) positioned over the image */}
//                             <Box
//                                 sx={{
//                                     position: 'absolute',
//                                     top: 21,
//                                     right: 21,
//                                     backgroundColor: 'rgba(255, 255, 255)',
//                                     borderRadius: 2,
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     padding: '4px 6px',
//                                 }}
//                             >
//                                 <IconButton sx={{ width: 8, height: 8, padding: 0, ml: 0.6, mr: 0.6 }}>
//                                     {workshop.gif === 'red' ? <Redlive /> : <Green />}
//                                 </IconButton>

//                                 <Typography
//                                     sx={{
//                                         color: 'black',
//                                         fontFamily: 'Poppins',
//                                         fontSize: 11,
//                                         fontWeight: 500,
//                                         ml: 0.8,
//                                         mt: 0.2,
//                                     }}
//                                 >
//                                     {workshop.status}
//                                 </Typography>
//                             </Box>

//                             {/* Title and Mentor */}
//                             <Typography
//                                 sx={{
//                                     color: '#100031',
//                                     fontFamily: 'Outfit',
//                                     fontSize: 16,
//                                     fontWeight: 600,
//                                     mb: 0.5,
//                                     ml: 0.6,
//                                     textAlign: 'left',
//                                 }}
//                             >
//                                 {workshop.workshop}
//                             </Typography>
//                             <Typography
//                                 sx={{
//                                     color: '#4b4b4b',
//                                     fontFamily: 'Poppins',
//                                     fontSize: 11,
//                                     fontWeight: 300,
//                                     mb: 0.5,
//                                     ml: 0.6,
//                                     textAlign: 'left',
//                                 }}
//                             >
//                                 {workshop.mentor ? `By ${workshop.mentor}` : <br />}
//                             </Typography>

//                             {/* Date Section */}
//                             <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, ml: 0.6 }}>
//                                 <img src={Calendar} alt="Calendar Icon" style={{ width: 14, height: 14, color: '#777777', marginBottom: 0 }} />
//                                 <Typography
//                                     sx={{
//                                         color: 'var(--Filled, #000)',
//                                         fontFamily: 'Poppins',
//                                         fontSize: 12,
//                                         fontWeight: 400,
//                                         ml: 1,
//                                     }}
//                                 >
//                                     {workshop.date}
//                                 </Typography>
//                             </Box>
//                         </CardContent>

//                         {/* Action Button */}
//                         <Button
//                             variant="contained"
//                             sx={{
//                                 width: {xs:"90%",sm:"264px"},
//                                 padding: '10px 50px',
//                                 backgroundColor: '#7b3fe4',
//                                 color: '#fff',
//                                 borderRadius: 2,
//                                 textTransform: 'none',
//                                 position: 'absolute',
//                                 bottom: 15,
//                                 left: '50%',
//                                 transform: 'translateX(-50%)',
//                                 boxShadow: 'none', // Removed box shadow
//                                 '&:hover': {
//                                     backgroundColor: '#7b3fe4', // Same color as the default
//                                     opacity: 1, // Ensure opacity remains the same
//                                     boxShadow:"none"
//                                 },
//                             }}
//                             href={workshop.btnLink}
//                         >
//                             {workshop.button || 'Know more'}
//                         </Button>
//                     </>
//                 )}
//             </Card>
//         </ButtonBase>
//     );
// };

// export default WorkshopCard;

import React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Button,
    Box,
    IconButton,
    CircularProgress,
} from '@mui/material';
import Calendar from '../../../assets/images/workshop/calendar_final.svg';
import Redlive from "../../redlive/redlive";
import Green from "../../green/Green";
import ButtonBase from '@mui/material/ButtonBase';

const WorkshopCard = ({ workshop = {}, loading = false }) => {
    const {
        btnLink = '#',
        bannerImg = '',
        workshop: workshopTitle = 'Workshop',
        gif = 'green',
        status = 'Live',
        mentor = 'Guest Mentor',
        date = 'TBD',
        button = 'Know more',
    } = workshop;

    return (
        <ButtonBase 
            disableRipple
            component="a"
            href={btnLink}
            sx={{
                width: '100%',
                display: 'block',
                textAlign: 'initial',
                '&:hover': {
                    textDecoration: 'none',
                },
            }}
        >
            <Card
                sx={{
                    width: '290px',
                    height: 335,
                    borderRadius: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: '1px solid #e0e0e0',
                    position: 'relative',
                    mx: 'auto',
                    '@media (max-width: 480px)': {
                        width: '90%',
                    },
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <CardContent sx={{ padding: 1.5, position: 'relative' }}>
                            {/* Image Section */}
                            <CardMedia
                                component="img"
                                image={bannerImg || Calendar}
                                alt={workshopTitle}
                                sx={{
                                    width: '100%',
                                    height: 140,
                                    marginBottom: 1.5,
                                    objectFit: 'cover',
                                    borderRadius: 4,
                                }}
                            />

                            {/* Header (Recorded session) positioned over the image */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 21,
                                    right: 21,
                                    backgroundColor: 'rgba(255, 255, 255)',
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '4px 6px',
                                }}
                            >
                                <IconButton sx={{ width: 8, height: 8, padding: 0, ml: 0.6, mr: 0.6 }}>
                                    {gif === 'red' ? <Redlive /> : <Green />}
                                </IconButton>

                                <Typography
                                    sx={{
                                        color: 'black',
                                        fontFamily: 'Poppins',
                                        fontSize: 11,
                                        fontWeight: 500,
                                        ml: 0.8,
                                        mt: 0.2,
                                    }}
                                >
                                    {status}
                                </Typography>
                            </Box>

                            {/* Title and Mentor */}
                            <Typography
                                sx={{
                                    color: '#100031',
                                    fontFamily: 'Outfit',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    mb: 0.5,
                                    ml: 0.6,
                                    textAlign: 'left',
                                }}
                            >
                                {workshopTitle}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#4b4b4b',
                                    fontFamily: 'Poppins',
                                    fontSize: 11,
                                    fontWeight: 300,
                                    mb: 0.5,
                                    ml: 0.6,
                                    textAlign: 'left',
                                }}
                            >
                                {mentor ? `By ${mentor}` : <br />}
                            </Typography>

                            {/* Date Section */}
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, ml: 0.6 }}>
                                <img src={Calendar} alt="Calendar Icon" style={{ width: 14, height: 14, color: '#777777', marginBottom: 0 }} />
                                <Typography
                                    sx={{
                                        color: 'var(--Filled, #000)',
                                        fontFamily: 'Poppins',
                                        fontSize: 12,
                                        fontWeight: 400,
                                        ml: 1,
                                    }}
                                >
                                    {date}
                                </Typography>
                            </Box>
                        </CardContent>

                        {/* Action Button */}
                        <Button
                            variant="contained"
                            sx={{
                                width: {xs:"90%",sm:"264px"},
                                padding: '10px 50px',
                                backgroundColor: '#7b3fe4',
                                color: '#fff',
                                borderRadius: 2,
                                textTransform: 'none',
                                position: 'absolute',
                                bottom: 15,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: '#7b3fe4',
                                    opacity: 1,
                                    boxShadow:"none"
                                },
                            }}
                            href={btnLink}
                        >
                            {button}
                        </Button>
                    </>
                )}
            </Card>
        </ButtonBase>
    );
};

export default WorkshopCard;
