import React, { useState, useEffect } from "react";
import {
  Box,
  Pagination,
  Stack,
  Typography,
  Tabs,
  Tab,
  Skeleton,
} from "@mui/material";
import ProjectCard from "./projectcard";
import ProjectFilters from "./projectfilters";
import data1 from "../../../global/projects1.json";
import "./middle.scss";

const Card = ({ targetFeaturedProjects }) => {
  const [filteredData, setFilteredData] = useState(data1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjects = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const noofpages = Math.ceil(filteredData.length / itemsPerPage);

  const labels = ["All Projects", "Academic Projects", "Skill Projects"];
  const s_labels = ["All", "Academic", "Skill"];

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [filteredData, currentPage]);

  const filterProjects = (filters) => {
    let filtered = data1;

    // Search by title or objective
    if (filters.searchQuery) {
      filtered = filtered.filter(
        (project) =>
          project.title
            .toLowerCase()
            .includes(filters.searchQuery.toLowerCase()) ||
          project.objective
            .toLowerCase()
            .includes(filters.searchQuery.toLowerCase())
      );
    }

    // Filter by category
    if (filters.category) {
      filtered = filtered.filter((project) =>
        project.category.toLowerCase().includes(filters.category.toLowerCase())
      );
    }

    // Filter by skill
    if (filters.skill) {
      filtered = filtered.filter((project) =>
        project.skill.some((skill) =>
          skill.toLowerCase().includes(filters.skill.toLowerCase())
        )
      );
    }

    // Filter by difficulty
    if (filters.difficulty) {
      filtered = filtered.filter(
        (project) =>
          project.difficulty.toLowerCase() === filters.difficulty.toLowerCase()
      );
    }

    setFilteredData(filtered);
  };

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1);
    let filteredTabData = data1;

    if (newValue === 0) {
      filteredTabData = data1;
    } else if (newValue === 1) {
      filteredTabData = data1.filter((project) => project.type === "academic");
    } else if (newValue === 2) {
      filteredTabData = data1.filter((project) => project.type === "real");
    } else if (newValue === 3) {
      filteredTabData = data1;
    } else if (newValue === 4) {
      filteredTabData = data1.filter((project) => project.type === "academic");
    } else if (newValue === 5) {
      filteredTabData = data1.filter((project) => project.type === "real");
    }

    setFilteredData(filteredTabData);
  };

  return (
    <Box sx={{ padding: "0px 10px", maxWidth: "1200px", margin: "0 auto" }}>
      <Typography
        ref={targetFeaturedProjects}
        className="header-middle-project"
        style={{
          scrollMarginTop: "80px",
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "28px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        <span style={{ color: "#000" }}>Featured </span>
        <span style={{ color: "#FF6B6B" }}>Projects</span>
      </Typography>
      <Typography
        className="normal-middle-project"
        style={{
          textAlign: "center",
          marginBottom: "30px",
          fontSize: "16px",
          color: "#666",
        }}
      >
        Find interesting projects and get started with an execution plan
      </Typography>
      <Tabs
        id="mid"
        value={selectedTab}
        onChange={handleTabChange}
        variant="standard"
        sx={{
          ".MuiTabs-flexContainer": {
            justifyContent: "flex-start",
            borderBottom: "1px solid #E0E0E0",
          },
          ".MuiTab-root": {
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "400",
            padding: "8px 16px",
            fontFamily: "Outfit",
            color: "#666",
            transition: "color 0.3s ease, font-weight 0.3s ease",
            "&.Mui-selected": {
              color: "#FF6B6B",
              fontWeight: "600",
            },
            "&:hover": {
              color: "#FF6B6B",
            },
          },
          ".MuiTabs-indicator": {
            backgroundColor: "#FF6B6B",
            height: "3px",
            borderRadius: "2px",
            transition: "all 0.3s ease",
          },
        }}
      >
        <Tab
          label={labels[0]}
          className="tbl"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        />
        <Tab
          label={labels[1]}
          className="tbl"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        />
        <Tab
          label={labels[2]}
          className="tbl"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        />
        <Tab
          label={s_labels[0]}
          className="stbl"
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        />
        <Tab
          label={s_labels[1]}
          className="stbl"
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        />
        <Tab
          label={s_labels[2]}
          className="stbl"
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        />
      </Tabs>

      <div className="project-container">
        <ProjectFilters onFilterChange={filterProjects} data1={data1} />

        {/* Display loading skeleton when loading */}
        {loading ? (
          <div className="project-grid">
            {[...Array(itemsPerPage)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={375}
                height={400}
                style={{ margin: "10px", borderRadius: "16px" }}
                className="flicker-skeleton"
              />
            ))}
          </div>
        ) : currentProjects.length === 0 ? (
          <Typography
            variant="h6"
            align="center"
            sx={{ margin: "20px 0", color: "#666" }}
          >
            No Projects Found
          </Typography>
        ) : (
          <div className="project-grid">
            {currentProjects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        )}

        <div className="pagination-box">
          {currentProjects.length === 0 ? (
            <></>
          ) : (
            <Stack spacing={2}>
              <Pagination
                count={noofpages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                variant="outlined"
                shape="rounded"
                siblingCount={1}
                boundaryCount={1}
              />
            </Stack>
          )}
        </div>
      </div>
    </Box>
  );
};

export default Card;
