import "./topLayer.scss";
import React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Image1 from "../../../assets/images/mockInterview/1st.png";
import Image2 from "../../../assets/images/mockInterview/job.svg";
import Image3 from "../../../assets/images/mockInterview/arrow_.svg";
import Image4 from "../../../assets/images/mockInterview/bag.svg";
import Image5 from "../../../assets/images/mockInterview/5th.png";
import Rocket from "../../../assets/images/mockInterview/rocket.svg";
import Bulb from "../../../assets/images/mockInterview/lightbulb_final.svg";
import Correct from "../../../assets/images/mockInterview/correct.svg";
import WaterMark from "../../../assets/images/mockInterview/watermark.svg";
import curve1 from "../../../assets/images/mockInterview/curve1.svg";
import curve2 from "../../../assets/images/mockInterview/curve2.svg";
import AnimatedTypography from "../subComponents/AnimatedTypography";
const TopLayer = ({ targetJoinNow }) => {
  return (
    <Box className="gradient-background">
      <Box sx={{ margin: "0 20px" }}>
        <Box
          className="wrapper-box-1"
          sx={{ display: "flex", gap: "40px", alignItems: "center" }}
        >
          <Box
            className="wrapper-box-2"
            sx={{ display: "flex", flexDirection: "column", gap: "25px" }}
          >
            <Typography className="header-text-top">
              Boost your career with{" "}
              <span className="gradient-text">Expert counselling</span> and
              <span className="gradient-text"> Mock interviews</span>
            </Typography>

            {/* <Box className="adv-box">
                <Box
                  sx={{ width: "26px", height: "26px" }}
                  component="img"
                  src={Bulb}
                />
                <Typography className="adv-text">

                  Gain valuable insights
                </Typography>
              </Box> */}








            {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center", // Vertically centers items within the container
                  justifyContent: "flex-start", // Ensures all items start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Optional: Adjust padding as needed
                }}
              >
                <Box
                  component="img" // Correctly specify 'component' within Box
                  src={Rocket}
                  sx={{
                    width: "23px",
                    height: "23px"
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Set font size as needed
                    color: "#000", // Set text color if needed
                    textAlign: "left", // Ensures text alignment is left
                  }}
                >
                  Boost Your Interview Skills
                </Typography>
              </Box> */}




            <Box
              className="wrapper-box-3"
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="adv-box"
                sx={{
                  display: "flex",
                  alignItems: "left", // Aligns items vertically
                  justifyContent: "flex-start", // Ensures contents start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Adjust padding as needed
                  flexDirection: "row", // Makes the text stack below the icon
                  textAlign: "left", // Ensures text aligns to the left
                  "@media (min-width: 768px)": {
                    flexDirection: "row",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                  "@media (min-width: 900px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                }}
              >
                <Box
                  component="img"
                  src={Bulb}
                  sx={{
                    width: "22px", // Adjust icon size as needed
                    height: "22px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Adjust font size
                    color: "#000", // Set text color
                    textAlign: "left", // Ensures text alignment is left
                    lineHeight: "1.2", // Adjust line height if needed
                  }}
                >
                  Get valuable insights

                </Typography>
              </Box>


              <Box
                className="adv-box"
                sx={{
                  display: "flex",
                  alignItems: "left", // Aligns items vertically
                  justifyContent: "flex-start", // Ensures contents start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Adjust padding as needed
                  flexDirection: "row", // Makes the text stack below the icon
                  textAlign: "left", // Ensures text aligns to the left
                  "@media (min-width: 768px)": {
                    flexDirection: "row",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                  "@media (min-width: 900px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                }}
              >
                <Box
                  component="img"
                  src={Rocket}
                  sx={{
                    width: "19px", // Adjust icon size as needed
                    height: "19px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Adjust font size
                    color: "#000", // Set text color
                    textAlign: "left", // Ensures text alignment is left
                    lineHeight: "1.2", // Adjust line height if needed
                  }}
                >
                  Boost Your Interview Skills

                </Typography>
              </Box>



              <Box
                className="adv-box"
                sx={{
                  display: "flex",
                  alignItems: "left", // Aligns items vertically
                  justifyContent: "flex-start", // Ensures contents start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Adjust padding as needed
                  flexDirection: "row", // Makes the text stack below the icon
                  textAlign: "left", // Ensures text aligns to the left
                  "@media (min-width: 768px)": {
                    flexDirection: "row",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                  "@media (min-width: 900px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                }}
              >
                <Box
                  component="img"
                  src={Correct}
                  sx={{
                    width: "18px", // Adjust icon size as needed
                    height: "18px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Adjust font size
                    color: "#000", // Set text color
                    textAlign: "left", // Ensures text alignment is left
                    lineHeight: "1.2", // Adjust line height if needed
                  }}
                >
                  Achieve Your Career Goals

                </Typography>
              </Box>
            </Box>


















            {/* <Box className="adv-box">
                <Box
                  sx={{ width: "23px", height: "23px" }}
                  component="img"
                  src={Correct}
                />
                <Typography className="adv-text">
                  Achieve Your Career Goals
                </Typography>
              </Box> */}
            {/* <Box
                className="adv-box"
                sx={{
                  display: "flex",
                  alignItems: "left", // Aligns items vertically
                  justifyContent: "flex-start", // Ensures contents start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Adjust padding as needed
                  flexDirection: "row", // Makes the text stack below the icon
                  textAlign: "left", // Ensures text aligns to the left
                  "@media (min-width: 768px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                }}
              >
                <Box
                  component="img"
                  src={Correct}
                  sx={{
                    width: "23px", // Adjust icon size as needed
                    height: "23px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Adjust font size
                    color: "#000", // Set text color
                    textAlign: "left", // Ensures text alignment is left
                    lineHeight: "1.2", // Adjust line height if needed
                  }}
                >
                  Achieve Your Career Goals
                </Typography>
              </Box> */}


            <Button
              sx={{
                justifyContent: "center", // Corrected property
                alignItems: "center", // Corrected property
              }}
              onClick={() => {
                if (targetJoinNow.current) {
                  targetJoinNow.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="gradient-button"
            >
              Join Now
            </Button>

          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "25px",
            }}
          >
            <Box component="img" src={Image1} className="img" />
            <Box
              className="img"
              sx={{
                position: "relative",
                borderRadius: "14px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Box
                className="job_wrapper"
                sx={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "30px",
                  background:
                    "linear-gradient(179deg, rgba(247, 240, 255, 0.09)-175.98%, rgba(114, 52, 247, 0.20)218.99%)",
                }}
              >
                <Box className="job_" component="img" src={Image2}></Box>
              </Box>
              <Box position="relative">
                <Box
                  className="curve-1"
                  top="71px"
                  left="30px"
                  position="absolute"
                  component="img"
                  src={curve1}
                ></Box>
                <Box
                  className="curve-2"
                  top=" 82px "
                  left="135px"
                  position="absolute"
                  component="img"
                  src={curve2}
                ></Box>
                <Typography
                  top="109px"
                  left="72px"
                  className="no_"
                  position="absolute"
                >
                  <AnimatedTypography>75</AnimatedTypography>%
                </Typography>
              </Box>
              <Typography className="text_">
                Participants say they feel more prepared for real interviews.
              </Typography>
            </Box>
            <Box
              className="img"
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Box className="wrapper-small-1">
                <Box display="flex" justifyContent="start" gap="14px">
                  <Box
                    alignSelf="start"
                    className="arrow"
                    component="img"
                    src={Image3}
                  />
                  <Box>
                    <Typography className="secondOutsideText">
                      <AnimatedTypography>100</AnimatedTypography>+
                    </Typography>
                    <Typography className="secondInsideText">
                      Individuals have advanced their careers with our support
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="wrapper-small-2">
                <Box display="flex" justifyContent="start" gap="14px">
                  <Box
                    alignSelf="start"
                    className="bag"
                    component="img"
                    src={Image4}
                  />
                  <Box>
                    <Typography className="thirdOutsideText">
                      <AnimatedTypography>85</AnimatedTypography>%
                    </Typography>
                    <Typography className="thirdInsideText">
                      Have successfully transitioned to their desired roles
                      within 6 months.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component="img" src={Image5} className="img" />
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        className="watermark-top"
        src={WaterMark}
        sx={{
          position: "absolute",
          bottom: "-185px",
          width: "400px",
          left: "0px",
        }}
      ></Box>
    </Box>
  );
};

export default TopLayer;
