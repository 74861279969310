import React, { useState } from "react";
import "./ele.scss";
import data1 from "../../../global/projects1.json";
import { useParams } from "react-router-dom";
import { Avatar, AvatarGroup, Dialog, DialogContent } from "@mui/material";
import sm1 from "../../../assets/images/ProjectsPage/sm1.png";
import sm2 from "../../../assets/images/ProjectsPage/sm2.png";
import sm3 from "../../../assets/images/ProjectsPage/sm3.png";
import begineer from "../../../assets/images/ProjectsPage/begineer.svg";
import intermediate from "../../../assets/images/ProjectsPage/Intermediate.svg";
import hard from "../../../assets/images/ProjectsPage/Hard.svg";
import ProjectCard from "../../projectPage/middle/projectcard";
import ic1 from "../../../assets/images/ProjectsPage/ic1.svg";
import ic2 from "../../../assets/images/ProjectsPage/ic2.svg";
import ic3 from "../../../assets/images/ProjectsPage/ic3.svg";
import ic4 from "../../../assets/images/ProjectsPage/ic4.svg";
import Projectsform from "../form/project-form";

const MainElement = () => {
  const { title } = useParams();
  const ptitle = title.replace(/_/g, " ");
  const data = data1.find((project) => project.title === ptitle);
  const dataf = data1.filter(
    (project) => project.difficulty === data.difficulty
  );
  const data2 = dataf.filter((project) => project.title !== data.title);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="container">
      <div className="head-container">
        <div className="first-left">
          <ProjectHeader
            p_category={data.category}
            p_title={data.title}
            p_users={data.usersJoined}
          />
          <ProjectOverview
            p_overview={data.objective}
            p_difficulty={data.difficulty}
          />
        </div>
        <div className="first-right">
          <div className="pbutton" onClick={handleClickOpen}>
            Join Waitlist
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <Projectsform
                handleCloseDialog={handleClose}
                title={data.title}
              />
            </DialogContent>
          </Dialog>
          <SkillsSection p_skills={data.skill} />
        </div>
      </div>

      {/* <div className="mid-cont">
        <div className="midd">Interested in this project?</div>
        <div className="pbutton" style={{ width: "360px" }}>
          Start Project
        </div>
      </div> */}
      <div className="recommended-projects">
        <div className="rpt">Recommended projects</div>
      </div>
      <RecommendedProjects data={data2} />
    </div>
  );
};

const ProjectHeader = ({ p_category, p_title, p_users }) => (
  <div className="hcon">
    <div className="chip">{p_category}</div>
    <div className="ptitle">{p_title}</div>
    <div className="users">
      {/* <AvatarGroup max={3}>
        <Avatar alt="User 1" className="user-avatar" src={sm3} />
        <Avatar alt="User 2" className="user-avatar" src={sm2} />
        <Avatar alt="User 3" className="user-avatar" src={sm1} />
      </AvatarGroup> */}
      <div className="pusers">{p_users}+ Users have joined this project</div>
    </div>
  </div>
);

const ProjectOverview = ({ p_overview, p_difficulty }) => (
  <div className="project-overview">
    <div className="overview-features">
      <div className="ofb">
        <div className="ofi">
          <img src={ic1} alt="1" className="ofim" />
          Collaborative Learning
        </div>
        <div className="ofi">
          <img src={ic2} alt="2" className="ofim" />
          Mentorship Guidance
        </div>
      </div>
      <div className="ofb">
        <div className="ofi">
          <img src={ic3} alt="3" className="ofim" />
          Realtime Project Experience
        </div>
        <div className="ofi">
          <img src={ic4} alt="4" className="ofim" />
          Gain hands-on Experince
        </div>
      </div>
    </div>
    <div className="overview-text">
      <div className="pot">Project Overview</div>
      <div className="poo">{p_overview}</div>
    </div>
    <div className="dft">Difficulty level:</div>
    <div className="difficulty">
      {p_difficulty === "Beginner" && (
        <img src={begineer} alt="level" className="image-level" />
      )}
      {p_difficulty === "Intermediate" && (
        <img src={intermediate} alt="level" className="image-level" />
      )}
      {p_difficulty === "Advanced" && (
        <img src={hard} alt="level" className="image-level" />
      )}
      {p_difficulty}
    </div>
  </div>
);

const SkillsSection = ({ p_skills }) => (
  <div className="skills-section">
    <div className="sst">Required skills</div>
    <div className="skills-list">
      {p_skills.map((skill, index) => (
        <div key={index} className="sli">
          {skill}
        </div>
      ))}
    </div>
    {/* <div className="mentor-section">
      <div className="mst">Mentor by</div>
      <div className="m_name">Sundar Pichai</div>
      <div className="m_des">Software Engineer @Google</div>
      <div className="m_mdes">
        Develop a real-time data processing system using Python and Flask, and
        implement features such as natural language processing, machine
        learning, and data visualization
      </div>
    </div> */}
  </div>
);

const RecommendedProjects = ({ data }) => (
  <div className="projects-grid">
    <div className="proj">
      <ProjectCard project={data[0]} />
    </div>
    <div className="proj">
      <ProjectCard project={data[1]} />
    </div>
  </div>
);

export default MainElement;
