import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import begineer from "../../../assets/images/ProjectsPage/begineer.svg";
import intermediate from "../../../assets/images/ProjectsPage/Intermediate.svg";
import hard from "../../../assets/images/ProjectsPage/Hard.svg";
import sm1 from "../../../assets/images/ProjectsPage/sm1.png";
import sm2 from "../../../assets/images/ProjectsPage/sm2.png";
import sm3 from "../../../assets/images/ProjectsPage/sm3.png";
import "./ProjectCard.scss";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const maxVisibleSkills = 3;
  const extraSkillsCount = project.skill.length - maxVisibleSkills;
  const navigate = useNavigate();

  const navproject = () => {
    let plink = project.title.replace(/\s+/g, "_");
    navigate(`/projects/${plink}`);
  };

  return (
    <Card className="project-card">
      <CardContent>
        <div className="header">
          <Chip
            size="small"
            label={project.category}
            className="chip-category"
          />
          <div className="level-box">
            {project.difficulty === "Beginner" && (
              <img src={begineer} alt="level" className="image-level" />
            )}
            {project.difficulty === "Intermediate" && (
              <img src={intermediate} alt="level" className="image-level" />
            )}
            {project.difficulty === "Advanced" && (
              <img src={hard} alt="level" className="image-level" />
            )}
            <Chip
              size="small"
              label={project.difficulty}
              className="chip-level"
            />
          </div>
        </div>

        <div className="title-box">
          <Typography variant="h5" component="div" className="title">
            {project.title}
          </Typography>
        </div>

        <div className="description-box">
          <Typography variant="body2" className="description">
            {project.description}
          </Typography>
        </div>

        <Typography className="tool-head">Tools</Typography>

        <div className="tools">
          {project.skill.slice(0, maxVisibleSkills).map((tool, index) => (
            <Chip key={index} label={tool} className="tool-chip" />
          ))}
          {extraSkillsCount > 0 && (
            <Chip
              label={`+${extraSkillsCount}`}
              className="tool-chip extra-skill-chip"
            />
          )}
        </div>

        <div className="users">
          {/* <AvatarGroup max={3}>
            <Avatar alt="User 1" className="user-avatar" src={sm3} />
            <Avatar alt="User 2" className="user-avatar" src={sm2} />
            <Avatar alt="User 3" className="user-avatar" src={sm1} />
          </AvatarGroup> */}
          {/* <Typography variant="body2" className="joined-text">
            {project.usersJoined}+ Joined
          </Typography> */}
        </div>

        <div className="view-button-box">
          <Button
            variant="outlined"
            className="view-project-btn"
            onClick={() => navproject()}
            style={{ textTransform: "none" }}
          >
            view project
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
