import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Drawer,
  IconButton,
} from "@mui/material";
import search from "../../../assets/images/ProjectsPage/Search.svg";
import Filter from "../../../assets/images/ProjectsPage/Filter.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./projectfilters.scss";
import { getUniqueFilterValues } from "./filterUtils";

const ProjectFilters = ({ onFilterChange, data1 }) => {
  const [filterSelections, setFilterSelections] = useState({
    searchQuery: "",
    category: "",
    skill: "",
    difficulty: "",
  });

  // State for actual applied filters
  const [appliedFilters, setAppliedFilters] = useState({
    searchQuery: "",
    category: "",
    skill: "",
    difficulty: "",
  });

  // State for handling drawer visibility
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSelectionChange = (event) => {
    const { name, value } = event.target;
    const updatedSelections = { ...filterSelections, [name]: value };
    setFilterSelections(updatedSelections);
  };

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    const updatedSelections = { ...filterSelections, searchQuery };
    setFilterSelections(updatedSelections);
  };

  const applyFilters = () => {
    setAppliedFilters(filterSelections);
    onFilterChange(filterSelections);
  };

  const clearFilters = () => {
    const resetFilters = {
      searchQuery: "",
      category: "",
      skill: "",
      difficulty: "",
    };
    setFilterSelections(resetFilters);
    setAppliedFilters(resetFilters);
    onFilterChange(resetFilters);
  };

  // Use utility to extract unique values
  const { uniqueCategories, uniqueSkills, uniqueDifficulties } =
    getUniqueFilterValues(data1);

  return (
    <div className="filters-container">
      {/* Search bar */}
      <div className="search-bar-wrapper">
        <img src={search} alt="search" className="img-search" />
        <TextField
          placeholder="Search projects"
          // variant="outlined"
          // size="small"
          // className="search-bar"
          value={filterSelections.searchQuery}
          onChange={handleSearchChange}
          sx={{
            // display: "flex",
            // borderRadius: "var(--radius-radius-md, 0px 8px 8px 0px)",
            // background: "#FFF",
            // width: "374px",
            // height: "50px",
            // // padding: "16px var(--Spacing-space-10, 24px)",
            // alignItems: "center",
            // gap: "var(--Spacing-space-10, 24px)",
            // flexShrink: "0",
            fontFamily: "Poppins",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <button className="filter-icon" onClick={toggleFilter}>
          <img src={Filter} alt="filter" className="image-filter" />
        </button>
      </div>

      {/* Desktop view filters */}
      {/* <div className="desktop-filters"> */}
      <div className="filter-group">
        <Select
          name="category"
          value={filterSelections.category}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>
            Categories
          </MenuItem>
          {uniqueCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="skill"
          value={filterSelections.skill}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>
            Skills
          </MenuItem>
          {uniqueSkills.map((skill) => (
            <MenuItem key={skill} value={skill}>
              {skill}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="difficulty"
          value={filterSelections.difficulty}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>
            Difficulty Level
          </MenuItem>
          {uniqueDifficulties.map((difficulty) => (
            <MenuItem key={difficulty} value={difficulty}>
              {difficulty}
            </MenuItem>
          ))}
        </Select>
        <Button
          sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "#333",
            color: "white",
            padding: "8px 16px",
            width: "120px",
            height: "50px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}
          onClick={applyFilters}
        >
          Apply
        </Button>
        <Button
          sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "white",
            width: "120px",
            height: "50px",
            color: "black",
            border: "1px solid black",
            padding: "8px 16px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </div>
      {/* </div> */}

      {/* Drawer for mobile filter panel */}
      <Drawer
        anchor="bottom"
        open={isFilterOpen}
        onClose={toggleFilter}
        PaperProps={{
          sx: { height: "70%" },
        }}
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <div className="drawer-header">
          <IconButton onClick={toggleFilter}>
            <CloseIcon />
          </IconButton>
          <h3>Filter Projects</h3>
        </div>

        <div className="filter-content">
          <Select
            name="category"
            value={filterSelections.category}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Categories</MenuItem>
            {uniqueCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="skill"
            value={filterSelections.skill}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Skills</MenuItem>
            {uniqueSkills.map((skill) => (
              <MenuItem key={skill} value={skill}>
                {skill}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="difficulty"
            value={filterSelections.difficulty}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Difficulty Level</MenuItem>
            {uniqueDifficulties.map((difficulty) => (
              <MenuItem key={difficulty} value={difficulty}>
                {difficulty}
              </MenuItem>
            ))}
          </Select>

          <Button
            sx={{
              backgroundColor: "#333",
              color: "white",
              padding: "8px 16px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
            className="apply-button"
            onClick={() => {
              applyFilters();
              toggleFilter();
            }}
          >
            Apply
          </Button>
          <Button
            sx={{
              backgroundColor: "transparent",
              color: "#333",
              padding: "8px 16px",
              textTransform: "none",
              border: "1px solid #333",
            }}
            className="clear-button"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default ProjectFilters;
