import { Box, Typography } from "@mui/material";
import "../tools/Tools.scss";
import ToolsImages from "../../../assets/images/tools";
import git from "../../../assets/images/tools/git.png"



const Tools = ({courseContent}) => {
  const tools = courseContent?.tools
  const data = tools.map(tool => ({
    label: tool,
    icon: ToolsImages[tool] ? ToolsImages[tool] : git,
  }));
  console.log(data, tools);
  

  return (
    <Box
      className="toolsWrapper"
      sx={{
        mx: { md: "32px", xs: "16px" },
        mb: { md: "10px" },
        p: { md: "56px 50px 116px 50px", xs: "35px 16px 45px 16px" },
      }}
    >
      <Typography
        className="headerText"
        sx={{ fontSize: { xs: "20px", md: "36px" } }}
      >
        <span style={{ color: "#FF7262" }}> Tools </span>you will master
      </Typography>
      <Box className="toolCards" sx={{ pt:{xs:"26px",md: "64px"} }}>
        {data.map((data, index) => (
          <Box key={index} className="toolCardWrapper">
            <Box
              className="toolCard"
              sx={{
                p: { md: "18px 13px", xs: "6px" },
                m: { md: "10px", xs: "8px" },
                height: { xs: "98px", md: "134px" },
                width: { xs: "98px", md: "134px" },
                borderRadius: { xs: "6px", md: "16px" },
              }}
            >
              <Box
                component="img"
                src={data.icon}
                sx={{
                  height: { xs: "48px", md: "54px" },
                  width: { xs: "48px", md: "54px" },
                  borderRadius: { xs: "6px", md: "6px" },
                }}
              />
              <Typography
                sx={{ fontSize: { xs: "12px", md: "16px" } }}
                className="label"
              >
                {data.label}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Tools;
