import "../coursePreview/CoursePreview.scss";
import { Box, Typography, Button, DialogContent, Dialog } from "@mui/material";
import calender from "../../../assets/images/coursePreview/calender.svg";
import purpleBg from "../../../assets/images/coursePreview/purpleBg.png";
import download from "../../../assets/images/coursePreview/Download.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Curriculum from "../../commonComponents/curriculum/Curriculum";
import WaitlistForm from "../../coursePreview/joinWaitlistForm/WaitlistForm";

const CoursePreview = ({ courseContent }) => {
  const navigate = useNavigate();
  const handleApplyNow = () => {
    navigate(`/checkout/${courseContent?.learnmoreCourse}`);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [waitlistDialogOpen, setWaitlistDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenWaitlistDialog = () => {
    setWaitlistDialogOpen(true); 
  };
  const handleCloseWaitlistDialog = () => {
    setWaitlistDialogOpen(false);
  };

  const isWaitlistCourse = !["Data Science", "DevOps with AWS"].includes(courseContent.course);

  return (
    <Box className="coursePreviewWrapper">
      <Box
        className="coursePreview"
        sx={{
          p: { md: "55px 32px 38px 40px", xs: "23px 16px 18px 16px" },
          m: { xs: "16px ", md: "32px" },
        }}
      >
        <Box
          className="courseDetails"
          sx={{
            width: { xs: "100%", md: "50%" },
            p: { md: "40px 30px 0px 0px", xs: "0px" },
          }}
        >
          <Typography className="courseName">{courseContent.course}</Typography>
          <Box className="courseDuration">
            <Box component="img" sx={{ height: "20px", width: "full" }} src={calender} />
            <Typography className="label">{courseContent.period}</Typography>
          </Box>
          <Box
            className="responsiveImg"
            component="img"
            sx={{
              display: { md: "none", xs: "block" },
              width: { md: "0%", xs: "100%" },
              height: { xs: "190px" },
              borderRadius: "12px",
            }}
            src={courseContent.imageSource}
          />
          <Typography
            className="courseDesc"
            sx={{ p: { xs: "18px 0 28px 0px", md: "0px" } }}
          >
            {courseContent.courseDesc}
          </Typography>
          <Box className="btnWrapper">
            {isWaitlistCourse ? (
              <Button className="btn1" onClick={handleOpenWaitlistDialog}>
                Join Waitlist
              </Button>
            ) : (
              <>
                <Button className="btn1" onClick={handleApplyNow}>
                  Enroll now
                </Button>
                <Button
                  className="btn2"
                  startIcon={<img src={download} alt="download-icon" />}
                  onClick={handleOpenDialog}
                >
                  Download curriculum
                </Button>
              </>
            )}
            <Dialog
              onClose={handleCloseDialog}
              open={dialogOpen}
              PaperProps={{
                sx: {
                  width: { xs: "100%", md: "40%" },
                  m: { xs: "16px", md: "0px" },
                  borderRadius: "24px",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
                  minHeight: "380px",
                  fontFamily: '"Outfit", sans-serif !important',
                  border: "1px solid #C7C7C7",
                  pb: { xs: "10px", md: "10px" },
                },
              }}
            >
              <DialogContent sx={{ p: "0" }}>
                <Curriculum
                  handleCloseDialog={handleCloseDialog}
                  courseContent={courseContent}
                  courseName={courseContent.course}
                />
              </DialogContent>
            </Dialog>

            <Dialog onClose={handleCloseWaitlistDialog} open={waitlistDialogOpen}
            PaperProps={{
              sx: {
                width: { xs: "100%", md: "40%" },
                m: { xs: "16px", md: "0px" },
                borderRadius: "24px",
                backgroundColor: "#FFF",
                boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
                minHeight: "380px",
                fontFamily: '"Outfit", sans-serif !important',
                border: "1px solid #C7C7C7",
                pb: { xs: "10px", md: "10px" },
              },
            }}
            >
              <DialogContent>
                <WaitlistForm handleCloseDialog={handleCloseWaitlistDialog} courseContent={courseContent} />
              </DialogContent>
            </Dialog>


          </Box>
        </Box>
        <Box
          className="rightImg"
          component="img"
          sx={{
            zIndex: "1",
            display: { xs: "none", md: "block" },
            width: { xs: "0%", md: "50%" },
            height: { md: "410px" },
          }}
          src={courseContent.imageSource}
        />
        <Box
          component="img"
          src={purpleBg}
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            zIndex: "0",
            height: "100%",
            display: { xs: "none", md: "block" },
          }}
        />
      </Box>
    </Box>
  );
};

export default CoursePreview;
