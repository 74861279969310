export const getUniqueFilterValues = (data1) => {
  const uniqueCategories = [...new Set(data1.map((item) => item.category))];
  const uniqueSkills = [...new Set(data1.flatMap((item) => item.skill))];
  const uniqueDifficulties = [...new Set(data1.map((item) => item.difficulty))];

  return {
    uniqueCategories,
    uniqueSkills,
    uniqueDifficulties,
  };
};
