import { Box, Button, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import axios from 'axios';

import noWorkshopsImg from "../../../assets/images/workshop/error.svg";
import LSarrow from "../../../assets/images/workshop/left_secondary_arrow.svg";
import RSarrow from "../../../assets/images/workshop/right_secondary_arrow.svg";
import RParrow from "../../../assets/images/workshop/right_primary_arrow.svg";
import LParrow from "../../../assets/images/workshop/left_primary_arrow.svg";
import WorkshopCard from "../../workshop/worshopcard/worshopcard";
import "../workshops/Workshops.scss";
const StyledSlider = styled(Slider)({
  '& .slick-slide': {
    transition: 'transform 0.3s ease-in-out',
  },
  '& .slick-prev, & .slick-next': {
    zIndex: 10,
  },
});

function SampleNextArrow({ onClick, currentSlide, slideCount }) {
  const isDisabled = currentSlide >= slideCount - 4;
  const arrowSrc = isDisabled ? RParrow : RSarrow;

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        cursor: isDisabled ? 'default' : 'pointer',
        position: 'absolute',
        right: '-35px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: isDisabled ? 1 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <img
        src={arrowSrc}
        alt={isDisabled ? "Disabled Next Arrow" : "Next Arrow"}
        style={{ width: '30px', height: '30px' }}
      />
    </button>
  );
}

function SamplePrevArrow({ onClick, currentSlide }) {
  const isDisabled = currentSlide === 0;
  const arrowSrc = currentSlide >= 1 ? LSarrow : LParrow;

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        cursor: isDisabled ? 'default' : 'pointer',
        position: 'absolute',
        left: '-35px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: isDisabled ? 1 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <img
        src={arrowSrc}
        alt={isDisabled ? "Disabled Previous Arrow" : "Prev Arrow"}
        style={{ width: '30px', height: '30px' }}
      />
    </button>
  );
}

const Workshops = () => {
  const [currentWorkshops, setCurrentWorkshops] = useState(true);
  const [content, setContent] = useState([]);
  const [width, setWidth] = useState('1600px');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const sliderRef = useRef(null);

  const fetchWorkshops = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_LINK}/workshop-cards`);
      if (response.data && Array.isArray(response.data.workshopCards)) {
        setContent(response.data.workshopCards);
      } else {
        console.error("Expected `workshopCards` array, but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching workshop data:", error);
    }
  };

  useEffect(() => {
    fetchWorkshops();
  }, []);

  const animations = {
    initial: { opacity: 0, scale: 1 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1 },
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
    },
  };

  const allWorkshopsSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow currentSlide={currentSlide} />,
    nextArrow: <SampleNextArrow currentSlide={currentSlide} slideCount={content.length} />,
    cssEase: 'linear',
    afterChange: (index) => setCurrentSlide(index),
    beforeChange: () => setTotalSlides(content.length),
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 800, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <Box className="workshops">
    <Box className="headerText" sx={{ px: '16px', pb: { xs: '30px', sm: '40px' } }}>
      <Typography className="text1" sx={{ pb: '5px', fontSize: { xs: '20px', sm: '32px' } }}>
        Workshops
      </Typography>
      <Typography className="text2" sx={{ fontSize: { xs: '20px', sm: '32px' } }}>
        Learn from the best with our workshops
      </Typography>
    </Box>

      <Box className="btnWrapper"  sx={{ gap: { xs: '16px', sm: '24px' }, px: '16px' }}>
        <Button
           className="workshopBtn"
           sx={{ width: { xs: '50%', sm: '326px' }, height: { xs: '41px', sm: '68px' }, fontSize: { xs: '14px', sm: '20px' } }}
          style={{ color: currentWorkshops ? 'white' : '#0F002E', backgroundColor: currentWorkshops ? '#0F002E' : 'white' }}
          onClick={() => {
            setCurrentWorkshops(true);
            fetchWorkshops();
            setWidth('1600px');
          }}
        >
          All Workshops
        </Button>
        <Button
         sx={{ width: { xs: '50%', sm: '326px' }, height: { xs: '41px', sm: '68px' }, fontSize: { xs: '14px', sm: '20px' } }}
         className="workshopBtn"
         style={{ color: !currentWorkshops ? 'white' : '#0F002E', backgroundColor: !currentWorkshops ? '#0F002E' : 'white' }}
          onClick={() => {
            setCurrentWorkshops(false);
            setContent([]);  // Clear content for Upcoming Workshops
            setWidth('1600px');
          }}
        >
          Upcoming Workshops
        </Button>
      </Box>

      <Box sx={{
         width: '90%',
         p: { md: '40px 32px', xs: '25px 16px' },
         margin: 'auto',
         maxWidth: width,
         position: 'relative',
         boxShadow: 'none',
         '&:hover': { boxShadow: 'none' }
      }}>
        {content.length > 0 ? (
          <StyledSlider ref={sliderRef} {...allWorkshopsSettings}>
            {content.map((item, index) => (
              <motion.div key={index} initial="initial" animate="animate" exit="exit" transition="transition" variants={animations} style={{ display: 'inline-block' }}>
                <Box sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 10px',
                boxShadow: 'none',
                '&:hover': { boxShadow: 'none' }
                }}> 
                  <WorkshopCard workshop={item} />
                </Box>
              </motion.div>
            ))}
          </StyledSlider>
        ) : (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Box
              component="img"
              src={noWorkshopsImg}
              alt="No Workshops Available"
              sx={{ width: '150px', mb: 2 }}
            />
            <Typography variant="h6" sx={{ color: '#0F002E', fontFamily: 'Poppins, sans-serif' }}>
              <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                Currently, no workshops are available right now.
              </span>
              <br />
              <span style={{ fontWeight: '300' }}>
                Stay tuned for upcoming workshops!
              </span>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Workshops;


