import { Typography, Box } from "@mui/material";
import "../companies/Companies.scss";
import logo1 from "../../../assets/images/companies/logo1.png";
import logo2 from "../../../assets/images/companies/logo2.svg";
import logo3 from "../../../assets/images/companies/logo3.svg";
import logo4 from "../../../assets/images/companies/logo4.png";
import logo5 from "../../../assets/images/companies/logo5.png";
import logo6 from "../../../assets/images/companies/logo6.png";
import logo7 from "../../../assets/images/companies/logo7.svg";
import logo8 from "../../../assets/images/companies/logo8.svg";
import logo9 from "../../../assets/images/companies/logo9.svg";
const Companies = () => {
  const logos = [
    { logoImage: logo1 },
    { logoImage: logo2 },
    { logoImage: logo3 },
    { logoImage: logo4 },
    { logoImage: logo5 },
    { logoImage: logo6 },
    { logoImage: logo7 },
    { logoImage: logo8 },
    { logoImage: logo9 },
  ];
  const logosClone = [...logos, ...logos];
  return (
    <>
      {" "}
      <Box
        className="companiesSection"
        sx={{ p: { md: "5px 0px 25px 0px", xs: "15px 0px 5px 0px" } }}
      >
        <h3 className="headText">Get placed in leading MNC's</h3>
        {/* <Typography
          className="headText"
          sx={{
            fontSize: { xs: "12px", md: "16px" },
            px: "16px",
            lineHeight: { xs: "35px", md: "45px" },
          }}
        >
          Get placed in leading MNC's
        </Typography> */}

        {/* sub text */}
        {/* <Typography
          variant="body1"
          className="subText"
          sx={{ fontSize: { xs: "14px !important", md: "16px" }, px: "16px" }}
        >
          land your dream job in MAANG companies with
          <span style={{ fontWeight: "700" }}> coursevita’s </span> placement
          guidance
        </Typography> */}
        <Box
          className="companiesSlider"
          sx={{ mt: { md: "24px", xs: "12px" } }}
        >
          <Box className="innerSlider">
            {logosClone.map((logo, index) => (
              <Box
                key={index}
                className="logoContainer"
                sx={{
                  m: { xs: " auto 8px", md: " auto 30px" },
                  minWidth: { md: "120px", xs: "90px" },
                }}
              >
                <img src={logo.logoImage} alt="logo-img" />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Companies;
