import "./verticalSlider.scss";
import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
const VerticalSlider = ({
  setQuestionNo,
  currentSection,
  setCurrentSection,
  totalQuestion,
}) => {
  const sliderRef = useRef(null);
  const handleClick = (e) => {
    if (sliderRef.current) {
      // Get the height of the slider and the click position relative to the slider
      const sliderHeight = sliderRef.current.clientHeight;
      const clickY = e.clientY - sliderRef.current.getBoundingClientRect().top;

      // Number of sections
      const totalSections = totalQuestion;
      const sectionHeight = sliderHeight / totalSections;

      // Determine the section index based on the click position
      const sectionIndex = Math.floor(clickY / sectionHeight);

      // Update the current section
      setCurrentSection(Math.min(totalSections - 1, Math.max(0, sectionIndex)));
      setQuestionNo(sectionIndex + 1);
    }
  };
  //--------------

  const handleMouseDown = (e) => {
    // Start dragging only if the click is within the slider
    if (sliderRef.current.contains(e.target)) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      handleMouseMove(e); // Handle the first move immediately
    }
  };

  const handleMouseMove = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    const mouseY = e.clientY - sliderRect.top;
    const sliderHeight = sliderRect.height;

    // Calculate the new position based on the mouse's Y coordinate
    const newPosition = Math.min(
      Math.max(Math.floor((mouseY / sliderHeight) * totalQuestion), 0),
      8
    );

    setCurrentSection(newPosition);
    setQuestionNo(newPosition + 1);
  };

  const handleMouseUp = () => {
    // Stop dragging
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  //-----------
  return (
    <Box
      ref={sliderRef}
      onClick={handleClick}
      className="slider-wrapper-asses"
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
    >
      {[...Array(totalQuestion)].map((_, index) => (
        <div
          key={index}
          style={{ zIndex: "10", "--totalQuestion": totalQuestion }}
          className={` ${
            currentSection === index ? "active-slider-asses" : ""
          } slider-thumb-asses`}
        >
          {/* Section {index + 1} */}
        </div>
      ))}
    </Box>
  );
};

export default VerticalSlider;
