import "./landing.scss";
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Logo from "../../../assets/images/skillAssessment/coursevita-logo-asses.svg";
import Clock from "../../../assets/images/skillAssessment/clock.svg";
const landing = ({ setIsLanding }) => {
  return (
    <Box className="topWrapper-landing-asses">
      <Box className="logo-landing-asses" component="img" src={Logo}></Box>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="contentBox-landing-asses">
          <Typography className="text-contentBox-landing-asses">
            Step into choosing right path with
          </Typography>
          <Typography className="text-contentBox-landing-asses-2">
            Coursevita
          </Typography>
          <Typography className="text-contentBox-landing-asses-3">
            Take our quiz and find the perfect fit for you!
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="10px"
          >
            <Button
              onClick={() => {
                setIsLanding(false);
              }}
              className="btn-contentBox-landing-asses"
            >
              Start
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="10px"
            gap="5px"
          >
            <Typography className="text-contentBox-landing-asses-4">
              Takes 1 Min
            </Typography>
            <Box component="img" src={Clock}></Box>
          </Box>
        </Box>
      </Box>

      <Typography className="text-contentBox-landing-asses-5">
        Powered by{" "}
        <span style={{ fontWeight: "300", fontFamily: "Poppins" }}>
          CourseVita
        </span>
      </Typography>
    </Box>
  );
};

export default landing;
